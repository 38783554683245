import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FaExclamationTriangle, FaExternalLinkAlt } from 'react-icons/fa'
import { ClientDatabaseComplete } from '../../../types/IClientDatabase'
import BatchModal, { BatchErrorData } from './BatchModal'
import './Dashboard.scss'

type Props = {
  clientDatabases: ClientDatabaseComplete[]
  dbActualVersion: number
}

const Dashboard = ({ clientDatabases, dbActualVersion }: Props) => {
  const [partnersDbs, setPartnersDbs] = useState<ClientDatabaseComplete[]>([])
  const [showBatchesModal, setShowBatchesModal] = useState<boolean>(false)
  const [batchesModalData, setBatchesModalData] = useState<BatchErrorData>()

  const triggerBatchesModal = (db: ClientDatabaseComplete) => {
    setBatchesModalData(new BatchErrorData(db))
    setShowBatchesModal(true)
  }

  useEffect(() => {
    setPartnersDbs(
      clientDatabases.sort((prev, next) => {
        if (prev.companyName < next.companyName) {
          return -1
        }
        return 1
      })
    )
  }, [clientDatabases])

  return (
    <section id='dashboard-wrapper'>
      <BatchModal
        isVisible={showBatchesModal}
        data={batchesModalData}
        handleHide={() => {
          setShowBatchesModal(false)
        }}
      />
      {partnersDbs.map(partnerDb => {
        return (
          <div key={partnerDb.dbId} className='pb-1'>
            <Card className='row g-0'>
              <Card.Header className={partnerDb.isWithoutErrors() ? 'no-issues' : 'with-issues'}>
                <div className='d-flex flex-row justify-content-between w-100 align-items-center'>
                  <div>
                    <span className='fw-bold'>{partnerDb.companyName}</span>
                    {partnerDb.adminUrl !== null && (
                      <span
                        className='admin-button ps-3'
                        onClick={() => {
                          window.open(partnerDb.adminUrl)
                        }}
                      >
                        <FaExternalLinkAlt />
                      </span>
                    )}
                    {/* <span className='text-muted' style={{ marginLeft: '.5rem' }}>
                      [{partnerDb.database}]
                    </span> */}
                  </div>

                  <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div style={{ marginRight: '.5rem' }}>
                      {partnerDb.countBatchErrors() > 0 && (
                        <button
                          onClick={() => triggerBatchesModal(partnerDb)}
                          className='button-nostyle'
                        >
                          <span className='text-warning warning-triangle'>
                            <FaExclamationTriangle style={{ fontSize: '1.25rem' }} />
                          </span>
                        </button>
                      )}
                    </div>
                    <div>
                      {partnerDb.version === dbActualVersion ? (
                        <span className='badge bg-success version-indicator'>
                          {partnerDb.version}
                        </span>
                      ) : (
                        <span className='badge bg-danger version-indicator'>
                          {partnerDb.version - dbActualVersion}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Card.Header>

              {partnerDb.sites.length > 0 && (
                <Card.Body>
                  <div className='row gx-2'>
                    {partnerDb.sites.map(site => {
                      return (
                        <div key={site.id} className='col-12 col-sm-4 col-lg-2 d-flex mt-3'>
                          <button
                            type='button'
                            className={`btn w-100 ${
                              site.isRunning
                                ? 'btn-success'
                                : site.isRunning === undefined
                                ? 'btn-outline-secondary'
                                : 'btn-danger'
                            }`}
                            style={{ width: '10rem' }}
                            onClick={() => {
                              window.open(site.url)
                            }}
                          >
                            {site.name}
                            {/* <span
                              className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${
                                site.isRunning
                                  ? 'bg-success'
                                  : site.isRunning === undefined
                                  ? 'bg-secondary'
                                  : 'bg-danger'
                              }`}
                            >
                              {site.isRunning ? (
                                <FaCheck />
                              ) : site.isRunning === undefined ? (
                                <FaQuestion />
                              ) : (
                                <FaTimes />
                              )}
                            </span> */}
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </Card.Body>
              )}
            </Card>
          </div>
        )
      })}
    </section>
  )
}

export default Dashboard
