import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

const MainLayoutScrollable = ({ children }: Props) => (
  <>
    <main className='container-fluid flex-grow-1 d-flex flex-column flex-sm-row h-100 p-0 bg-main'>
      {children}
    </main>
  </>
)

export default MainLayoutScrollable
