import './MainContainer.scss'

type Props = {
  children?: React.ReactNode
}

const MainContainer = ({ children }: Props) => {
  return (
    <div className='col h-100' id='main-container'>
      <div className='container-xxl h-100'>{children}</div>
    </div>
  )
}

export default MainContainer
