import axios from 'axios'
import { useEffect, useState } from 'react'
import { API_UPTIME_BASE_URL } from '../../consts'
import { ClientDatabaseComplete } from '../../types/IClientDatabase'
import IUptimeData from '../../types/IUptimeData'

export const useUptimeUpdate = (databases: ClientDatabaseComplete[]) => {
  const [clientDatabases, setClientDatabases] = useState<ClientDatabaseComplete[]>(databases)

  const updateUptimeData = async () => {
    const uptimeData = await axios.get<IUptimeData>(`${API_UPTIME_BASE_URL}/UpTime`)
    if (uptimeData.status >= 200 && uptimeData.status < 300) {
      clientDatabases.forEach(clientDb => {
        clientDb.sites.forEach(site => {
          const monitor = uptimeData.data.monitors.find(monitor => monitor.id === site.externalId)
          if (monitor !== undefined) {
            if (monitor.status === 2) {
              site.isRunning = true
            } else {
              site.isRunning = false
            }
          }
        })
      })
      setClientDatabases([...clientDatabases])
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updateUptimeData()
    }, 60000)

    return () => clearInterval(interval)
  })

  return { clientDatabases, setClientDatabases }
}
