import axios from 'axios'
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { DropdownButton } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import Loading from '../../comps/Loading'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import { useAuth } from '../../contexts/AuthContext'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import IScriptInsertItem from '../../types/IScriptInsertItem'

type urlParams = {
  db: string
  num: string
}

const defaultScript: IScriptDetailItem = {
  id: 0,
  databaseId: 0,
  number: 0,
  name: '',
  isBreakingChange: false,
  date: '',
  userInitials: '',
  sql: '',
}

const ScriptEdit = () => {
  const params: urlParams = useParams()
  const [script, setScript] = useState<IScriptDetailItem>(defaultScript)
  const [isLoadingScript, setIsLoadingScript] = useState<boolean>(false)
  const history = useHistory()
  const { user } = useAuth()

  const fetchScript = useCallback(async () => {
    setIsLoadingScript(true)
    const data = await axios.get<IScriptDetailItem>(
      `${API_BASE_URL}/Script/Get/${params.num}?db=${params.db}`
    )
    setIsLoadingScript(false)
    if (data.status >= 200 && data.status < 300) {
      setScript(data.data)
    }
  }, [params])

  const saveScript = async (isCreation: boolean, script: IScriptInsertItem, id: number) => {
    const url = isCreation ? `${API_BASE_URL}/Script/Add` : `${API_BASE_URL}/Script/Modify?id=${id}`

    setIsLoadingScript(true)
    const data = await axios.post<IScriptDetailItem>(url, script).catch(error => {
      console.log(error)
      if (error.response) {
        alert('Chyba ' + error.response.status)
      } else if (error.request) {
        alert(error.request)
      } else {
        alert('Chyba ' + error.message)
      }
      setIsLoadingScript(false)
    })
    setIsLoadingScript(false)

    if (data !== undefined && data.status >= 200 && data.status < 300) {
      history.push(`/Script/${data.data.databaseId}`)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name
    const value = e.target.value
    setScript({ ...script, [name]: value })
  }

  const setDb = useCallback(
    (dbId: number) => {
      setScript({ ...script, databaseId: dbId })
    },
    [script]
  )

  const getDbName = useCallback(() => {
    switch (script.databaseId) {
      case 1:
        return 'Benefit'
      case 2:
        return 'Pasport'
      case 3:
        return 'Doprava'
    }
    setDb(parseInt(params.db))
    return 'Neznama databaze'
  }, [script.databaseId, params.db, setDb])

  const toggleBreakingCheck = () => {
    setScript({ ...script, isBreakingChange: !script.isBreakingChange })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateForm()) {
      alert('Vyplňte všechny údaje o skriptu')
      return
    }

    const isCreation = params.num === undefined
    const newScript: IScriptInsertItem = {
      db: isCreation ? script.databaseId : 0,
      userName: user?.userName === undefined ? '' : user.userName,
      name: script.name,
      sql: script.sql,
      isBreakingChange: script.isBreakingChange,
    }

    saveScript(isCreation, newScript, isCreation ? 0 : script.id)
  }

  const validateForm = (): boolean => {
    if (user === undefined || user.userName === '') return false
    if (script === undefined) return false
    if (script.name === '') return false
    if (script.sql === '') return false
    return true
  }

  useEffect(() => {
    if (params.db !== undefined && params.num !== undefined) fetchScript()
  }, [fetchScript, params])

  return (
    <MainLayout>
      <MainContainer>
        <div id='scriptedit-wrapper' className='pt-3 pb-3 h-100'>
          {isLoadingScript ? (
            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
              <Loading />
            </div>
          ) : (
            <form onSubmit={e => handleSubmit(e)}>
              <h3 className='mb-3'>
                {params.db !== undefined && params.num !== undefined
                  ? 'Upravit skript'
                  : 'Nový skript'}
              </h3>
              {params.db !== undefined && params.num !== undefined && (
                <>
                  <div className='form-group row mb-3'>
                    <label className='col-sm-2 col-lg-1 col-form-label'>Číslo skriptu</label>
                    <div className='col-sm-3 col-lg-2 d-flex flex-column justify-content-center'>
                      <input
                        readOnly
                        type='text'
                        className='form-control'
                        value={`#${script.number}`}
                      />
                    </div>
                  </div>
                  <div className='form-group row mb-3'>
                    <label className='col-sm-2 col-lg-1 col-form-label'>Databáze</label>
                    <div className='col-sm-3 col-lg-2'>
                      <input readOnly type='text' className='form-control' value={getDbName()} />
                    </div>
                  </div>
                  <div className='form-group row mb-3'>
                    <label className='col-sm-2 col-lg-1 col-form-label'>Vytvořil</label>
                    <div className='col-sm-3 col-lg-2'>
                      <input
                        readOnly
                        type='text'
                        className='form-control'
                        value={script.userInitials}
                      />
                    </div>
                  </div>
                </>
              )}

              {params.num === undefined && (
                <div className='form-group row mb-3'>
                  <label className='col-sm-2 col-lg-1 col-form-label'>Databáze</label>
                  <div className='col-sm-3 col-lg-2'>
                    <DropdownButton
                      variant='secondary'
                      id='dropdown-basic-button'
                      title={getDbName()}
                    >
                      <button
                        className='dropdown-item'
                        onClick={e => {
                          e.preventDefault()
                          setDb(1)
                        }}
                      >
                        Benefit
                      </button>
                      <button
                        className='dropdown-item'
                        onClick={e => {
                          e.preventDefault()
                          setDb(2)
                        }}
                      >
                        Pasport
                      </button>
                      <button
                        className='dropdown-item'
                        onClick={e => {
                          e.preventDefault()
                          setDb(3)
                        }}
                      >
                        Doprava
                      </button>
                    </DropdownButton>
                  </div>
                </div>
              )}
              <div className='form-group row mb-3'>
                <label htmlFor='breakingCheck' className='col-sm-2 col-lg-1 col-form-label'>
                  Breaking Change
                </label>
                <div className='col-auto d-flex flex-column justify-content-center'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='breakingCheck'
                    name='isBreakingChange'
                    defaultChecked={script.isBreakingChange}
                    onChange={toggleBreakingCheck}
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label htmlFor='inputTitle' className='col-sm-2 col-lg-1 col-form-label'>
                  Název
                </label>
                <div className='col-sm-7 col-lg-6'>
                  <input
                    type='text'
                    className='form-control'
                    id='inputTitle'
                    placeholder='Název...'
                    name='name'
                    value={script.name}
                    onChange={e => handleChange(e)}
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label htmlFor='inputSql' className='col-sm-2 col-lg-1 col-form-label'>
                  Sql
                </label>
                <div className='col-sm-10 col-lg-11'>
                  <textarea
                    style={{ minHeight: '30vh', height: '50%' }}
                    className='form-control'
                    id='inputSql'
                    placeholder='Sql...'
                    name='sql'
                    value={script.sql}
                    onChange={e => handleChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <button type='submit' className='col-sm-3 col-md-2 col-lg-1 btn btn-primary'>
                  Uložit
                </button>
              </div>
            </form>
          )}
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default ScriptEdit
