import './Sites.scss'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import { ISiteInfo } from '../../types/ISiteInfo'
import moment from 'moment'

const Sites = () => {
  const [sites, setSites] = useState<ISiteInfo[]>([])

  const fetchSites = useCallback(async () => {
    const data = await axios.get<ISiteInfo[]>(`${API_BASE_URL}/Site`)
    setSites(
      data.data.sort((prev, next) => {
        if (prev.url < next.url) {
          return -1
        }
        return 1
      })
    )
  }, [])

  useEffect(() => {
    fetchSites()
  }, [fetchSites])

  return (
    <MainLayout>
      <MainContainer>
        <div id='sites-wrapper' className='mt-3'>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>Url</th>
                <th>Name</th>
                <th>Partner</th>
                <th>External ID</th>
                <th>Last published</th>
              </tr>
            </thead>
            <tbody>
              {sites.map((s, index) => (
                <tr>
                  <td>
                    <a target='_blank' href={s.url} rel='noreferrer'>
                      {s.url}
                    </a>
                  </td>
                  <td>{s.name}</td>
                  <td>{s.partner}</td>
                  <td>
                    {s.externalId > 0 && (
                      <a
                        target='_blank'
                        href={'https://uptimerobot.com/dashboard#' + s.externalId}
                        rel='noreferrer'
                      >
                        {s.externalId}
                      </a>
                    )}
                  </td>
                  <td>
                    {s.lastPublishUser !== null && (
                      <span>
                        {moment(new Date(s.lastPublishDate)).fromNow()} [{s.lastPublishUser}]
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default Sites
