import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import './TopMenu.scss'

const TopMenu = () => {
  const { checkIsAuthenticated, user } = useAuth()

  return (
    <Navbar bg='dark' variant='dark' fixed='top' id='top-menu'>
      <Container fluid>
        <LinkContainer to='/'>
          <Navbar.Brand>[DevZone]</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Link className='nav-link' to='/'>
              Dashboard
            </Link>
            <Link className='nav-link' to='/Script'>
              Skripty
            </Link>
            <Link className='nav-link' to='/Databases'>
              Databáze
            </Link>
            <Link className='nav-link' to='/Webs'>
              Weby
            </Link>
            <Link className='nav-link' to='/Tools'>
              Nástroje
            </Link>
          </Nav>
        </Navbar.Collapse>
        {checkIsAuthenticated() && user !== undefined && user !== null && (
          <Navbar.Text>{user.name}</Navbar.Text>
        )}
      </Container>
    </Navbar>
  )
}

export default TopMenu
