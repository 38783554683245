import React from 'react'
import ScriptCard from './ScriptCard'
import IScriptDetailItem from '../types/IScriptDetailItem'

type Props = {
  scripts: IScriptDetailItem[]
  selectedNum?: string
  onScriptClicked?: Function
}

const ScriptList = ({ scripts, selectedNum, onScriptClicked }: Props) => {
  return (
    <>
      {scripts.map(script => {
        return (
          <div className='mb-3' key={script.number}>
            <ScriptCard
              script={script}
              selected={selectedNum === undefined ? false : parseInt(selectedNum) === script.number}
              handleClick={() => onScriptClicked && onScriptClicked(script)}
            />
          </div>
        )
      })}
    </>
  )
}

export default ScriptList
