import React from 'react'
import { Card } from 'react-bootstrap'
import IToolItem from '../../../types/IToolItem'
import './ToolCard.scss'

type Props = {
  handleClick?: Function
  tool: IToolItem
}

const ToolCard = ({ handleClick, tool }: Props) => {
  return (
    <Card onClick={() => handleClick !== undefined && handleClick()} id='card'>
      <Card.Header>
        <h4 className='title'>
          {tool.icon}
          <span> {tool.name}</span>
        </h4>
      </Card.Header>
      <Card.Body>
        <Card.Text>{tool.description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ToolCard
