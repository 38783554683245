import { UserAgentApplication } from 'msal'

export const isIE = () => {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') > -1
  const msie11 = ua.indexOf('Trident/') > -1

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11
}

export const msalConfig = {
  auth: {
    clientId: '0c805564-697b-4085-bfe8-954a42b58dae',
    authority: 'https://login.microsoftonline.com/23706153-cc00-4dce-b18f-9bf767ba7ee5', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: 'http://localhost:3000',
    redirectUri: 'https://devzone-beta.benefitcz.cz',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: isIE(), // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    navigateFrameWait: 0,
  },
}

export const msalApp = new UserAgentApplication({
  auth: msalConfig.auth,
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: isIE(), // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: msalConfig.system,
})

export const loginRequest = {
  scopes: ['User.Read'],
}
