import React from 'react'
import MainContainer from '../../comps/MainContainer'
import MainLayout from '../../layouts/MainLayout'
import ToolCard from './comps/ToolCard'
import IToolItem from '../../types/IToolItem'
import { FaDatabase, FaLock } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { Card } from 'react-bootstrap'

const onlineTools: IToolItem[] = [
  {
    name: 'Script Guide',
    description:
      'Pomocník pro psaní SQL skriptů - pomůže s ošetřením základních konstrukcí typu: nová tabulka, nový sloupec apod.',
    icon: <FaDatabase />,
    path: '/Tools/ScriptGuide',
  },
  {
    name: 'Enc-Dec',
    description:
      'Šifrovací/dešifrovací utilitka rychlé vytvoření zašifrované hodnoty nebo její dešifrování. Algoritmus je používán v našich .NET projektech.',
    icon: <FaLock />,
    path: '/Tools/EncDec',
  },
]

const Tools = () => {
  const history = useHistory()

  return (
    <MainLayout>
      <MainContainer>
        <div className='pt-3 row gx-5'>
          <div className='col'>
            <h3 className='mb-3'>Nástroje on-line</h3>
            {onlineTools.map((tool, index) => {
              return (
                <div className='mb-3' key={index}>
                  <ToolCard tool={tool} handleClick={() => history.push(tool.path)} />
                </div>
              )
            })}
          </div>
          <div className='col'>
            <h3 className='mb-3'>Nástroje ke stažení</h3>
            <div className='mb-3'>
              <Card>
                <Card.Header>
                  <h4 className='title'>
                    <FaDatabase />
                    <span> bnf-db</span>
                  </h4>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Klient pro synchronizaci databáze. Návod pro instalaci a použití je na
                    <a
                      href='https://github.com/BenefitCZ/support-kb/blob/main/DB/Synchronizace/bnf-db.md'
                      target='_blank'
                      rel='noreferrer'
                    >
                      &nbsp;GitHub
                    </a>
                    <span>.</span>
                    <div>
                      <ul>
                        <li>
                          <a href='https://download.benefitcz.cz/bnf-db/bnf-db-framework-depend.zip'>
                            Light verze
                          </a>
                        </li>
                        <li>
                          <a href='https://download.benefitcz.cz/bnf-db/bnf-db-standalone.zip'>
                            Standalone verze
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default Tools
