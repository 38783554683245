import React from 'react'
import { useLoading, ThreeDots as Visual } from '@agney/react-loading'

const Loading = () => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Visual />,
  })
  return (
    <section style={{ width: '5rem' }} {...containerProps}>
      {indicatorEl} {/* renders only while loading */}
    </section>
  )
}

export default Loading
