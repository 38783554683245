import { Card } from 'react-bootstrap';
import IScriptDetailItem from '../types/IScriptDetailItem';
import './ScriptCard.scss';

type Props = {
    script: IScriptDetailItem,
    selected?: boolean,
    handleClick?: Function
}

const ScriptCard = ({ script, selected, handleClick }: Props) => {
    const date: Date = new Date(script.date)

    return (
        <Card onClick={() => handleClick !== undefined && handleClick() } id='card' className={selected ? 'selected' : ''}>
            <Card.Header>
                <div className='d-flex flex-row justify-content-between'>
                    <div>
                        <h5>
                            {script.number}
                        </h5>
                    </div>
                    <div className='text-muted'>
                        {date.getDate() + '.' + (date.getMonth() + 1) + '. '}
                        {'[' + script.userInitials + ']'}             
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {script.name}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ScriptCard
