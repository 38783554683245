import React from 'react'
import Router from './services/Router'
import axios from 'axios'
import { API_BASE_URL } from './consts'
import { AuthProvider } from './contexts/AuthContext'

const InitAxios = () => {
  axios.defaults.baseURL = API_BASE_URL

  axios.interceptors.request.use(config => {
    config.headers['Authorization'] =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW0iOiIyMDAxIn0.CCTf_T71FEqQwKkXFxn_wtSJoajUfewrXLhRQ4ryKgQ'
    // eslint-disable-next-line  no-console
    console.debug('axios URL:' + config.url)
    return config
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // if (error.response?.status === 401) {
      //   window.location.href = 'login'
      // }
      return Promise.reject(error)
    }
  )
}

document.title = 'DevZone'

const App = () => {
  InitAxios()
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  )
}

export default App
