import './Databases.scss'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import { IClientDatabase } from '../../types/IClientDatabase'
import moment from 'moment'

const Databases = () => {
  const [databases, setDatabases] = useState<IClientDatabase[]>([])

  const fetchDatabases = useCallback(async () => {
    const data = await axios.get<IClientDatabase[]>(`${API_BASE_URL}/ClientDatabase`)
    setDatabases(
      data.data.sort((prev, next) => {
        if (prev.companyName < next.companyName) {
          return -1
        }
        return 1
      })
    )
  }, [])

  useEffect(() => {
    fetchDatabases()
  }, [fetchDatabases])

  return (
    <MainLayout>
      <MainContainer>
        <div id='databases-wrapper' className='mt-3'>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Version</th>
                <th>Last synchro</th>
                <th>Admin</th>
                <th>Server</th>
                <th>DB</th>
              </tr>
            </thead>
            <tbody>
              {databases.map((s, index) => (
                <tr>
                  <td>{s.companyName}</td>
                  <td>{s.version}</td>
                  <td>
                    {s.lastDate !== null && <span>{moment(new Date(s.lastDate)).fromNow()}</span>}
                  </td>
                  <td>{s.adminUrl}</td>
                  <td>{s.server}</td>
                  <td>{s.database}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default Databases
