import { Account } from 'msal'
import React, { useContext, useEffect, useState } from 'react'
import { loginRequest, msalApp } from '../authUtils'

type IAuthContext = {
  checkIsAuthenticated: () => boolean
  user: Account | undefined
  login: () => void
}

const AuthContext = React.createContext<IAuthContext>({
  checkIsAuthenticated: () => {
    return false
  },
  user: undefined,
  login: () => {},
})

export function useAuth() {
  return useContext(AuthContext)
}

type Props = {
  children: any
}

export function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<Account>()

  const login = (): void => {
    msalApp.loginRedirect(loginRequest)
  }

  const checkIsAuthenticated = (): boolean => {
    const account = msalApp.getAccount()

    const now = Math.round(new Date().getTime() / 1000)

    if (account) {
      if (parseInt(account.idToken.exp) > now) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const account = msalApp.getAccount()

    const now = Math.round(new Date().getTime() / 1000)

    if (account) {
      if (parseInt(account.idToken.exp) > now) {
        setUser(account)
      }
    }
    if (!checkIsAuthenticated()) login()
  }, [])

  const value = {
    checkIsAuthenticated,
    user,
    login,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
