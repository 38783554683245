export interface ISiteInfo {
  id: number
  dbId: number
  externalId: number
  name: string
  url: string
  type: number
  lastPublishDate: string
  lastPublishUser: string
  iisAppName: string
  publishProjectName: string
  publishProfile: string
  partner: string
}

export class SiteInfoComplete {
  id: number
  dbId: number
  externalId: number
  name: string
  url: string
  type: number
  isRunning: boolean | undefined

  constructor(siteInfo: ISiteInfo) {
    this.id = siteInfo.id
    this.dbId = siteInfo.dbId
    this.externalId = siteInfo.externalId
    this.name = siteInfo.name
    this.url = siteInfo.url
    this.type = siteInfo.type
    this.isRunning = undefined
  }
}
