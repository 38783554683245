import React from 'react'
import { Card } from 'react-bootstrap'
import IScriptDetailItem from '../../../types/IScriptDetailItem'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialLight as Style } from 'react-syntax-highlighter/dist/esm/styles/prism'

type Props = {
  script: IScriptDetailItem
}

const ScriptDetail = ({ script }: Props) => {
  let date_string: string = ''
  if (script !== undefined) {
    const date = new Date(script.date)
    date_string = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  return (
    <Card>
      <Card.Header className='sticky-top' style={{ backgroundColor: 'lightGray' }}>
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex flex-column justify-content-center'>
            <h5 className='m-0'>{script.name}</h5>
          </div>
          <div className='text-muted'>
            <div>{date_string}</div>
            <div>{script.userInitials}</div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <SyntaxHighlighter language='sql' style={Style}>
          {script.sql}
        </SyntaxHighlighter>
      </Card.Body>
    </Card>
  )
}

export default ScriptDetail
