import MainContainer from '../../comps/MainContainer'
import MainLayout from '../../layouts/MainLayout'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { API_BASE_URL, API_UPTIME_BASE_URL } from '../../consts'
import { IClientDatabase, ClientDatabaseComplete } from '../../types/IClientDatabase'
import './Home.scss'
import Dashboard from './comps/Dashboard'
import Loading from '../../comps/Loading'
import { FaTrashAlt } from 'react-icons/fa'
import IUptimeData from '../../types/IUptimeData'
import { useUptimeUpdate } from './useUptimeUpdate'

const Home = () => {
  const [dbActualVersion, setDbActualVersion] = useState<number>(-1)
  const [loadingDbActualVersion, setLoadingDbActualVersion] = useState<boolean>(false)
  const { clientDatabases, setClientDatabases } = useUptimeUpdate([])
  const [loadingClientDatabases, setLoadingClientDatabases] = useState<boolean>(false)
  const [searchField, setSearchField] = useState<string>('')
  const [onlyWithIssues, setOnlyWithIssues] = useState<boolean>(false)

  const fetchLastScript = useCallback(async () => {
    setLoadingDbActualVersion(true)
    const data = await axios.get<IScriptDetailItem[]>(`${API_BASE_URL}/Script/Last?db=1&count=1`)
    setLoadingDbActualVersion(false)
    if (data.status >= 200 && data.status < 300) setDbActualVersion(data.data[0].number)
  }, [])

  const fetchClientDatabases = useCallback(async () => {
    setLoadingClientDatabases(true)
    const data = await axios.get<IClientDatabase[]>(`${API_BASE_URL}/ClientDatabase`)
    if (data.status >= 200 && data.status < 300) {
      const clientDbsComplete = data.data.map(clientDb => {
        return new ClientDatabaseComplete(clientDb)
      })
      const uptimeData = await axios.get<IUptimeData>(`${API_UPTIME_BASE_URL}/UpTime`)
      if (uptimeData.status >= 200 && uptimeData.status < 300) {
        clientDbsComplete.forEach(clientDb => {
          clientDb.sites.forEach(site => {
            const monitor = uptimeData.data.monitors.find(monitor => monitor.id === site.externalId)
            if (monitor !== undefined) {
              if (monitor.status === 2) {
                site.isRunning = true
              } else {
                site.isRunning = false
              }
            }
          })
        })
      }
      setClientDatabases(clientDbsComplete)
      setLoadingClientDatabases(false)
    }
  }, [setClientDatabases])

  useEffect(() => {
    fetchLastScript()
    fetchClientDatabases()
  }, [fetchLastScript, fetchClientDatabases])

  return (
    <MainLayout>
      <MainContainer>
        <section id='home-wrapper' className='h-100'>
          {loadingClientDatabases || loadingDbActualVersion ? (
            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
              <Loading />
            </div>
          ) : (
            dbActualVersion !== -1 && (
              <>
                <div className='top-bar d-flex flex-row justify-content-between align-items-center p-2'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='checkOnlyIssues'
                      onChange={() => setOnlyWithIssues(!onlyWithIssues)}
                    />
                    <label className='form-check-label' htmlFor='checkOnlyIssues'>
                      Jen problémové
                    </label>
                  </div>
                  <div className='d-flex flex-row justify-content-end'>
                    <input
                      type='text'
                      className='form-control search-field'
                      placeholder='Hledat...'
                      value={searchField}
                      onChange={e => setSearchField(e.target.value)}
                    />
                    {searchField.length > 0 && (
                      <button
                        className='btn btn-outline-danger'
                        onClick={() => {
                          setSearchField('')
                        }}
                      >
                        <FaTrashAlt />
                      </button>
                    )}
                  </div>
                </div>
                <div className='scrollable-column'>
                  <Dashboard
                    dbActualVersion={dbActualVersion}
                    clientDatabases={clientDatabases.filter(clientDb => {
                      const passesSearch = clientDb.companyName
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                      let passesOnlyIssuesCheck = true
                      if (onlyWithIssues && clientDb.isWithoutErrors()) {
                        passesOnlyIssuesCheck = false
                      }
                      return passesSearch && passesOnlyIssuesCheck
                    })}
                  />
                </div>
              </>
            )
          )}
        </section>
      </MainContainer>
    </MainLayout>
  )
}

export default Home
