import { useLoading, ThreeDots as Visual } from '@agney/react-loading'
import { Route } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const PrivateRoute = ({ isAuthenticated, ...rest }: any) => {
  const { checkIsAuthenticated } = useAuth()
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Visual />,
  })

  if (checkIsAuthenticated()) return <Route {...rest} />
  return (
    <section className='vh-100'>
      {/* <div className='h-100 alert alert-danger d-flex flex-column justify-content-center align-items-center'>
        Přihláste se do aplikace
      </div> */}
      <section style={{ width: '5rem' }} {...containerProps}>
        {indicatorEl} {/* renders only while loading */}
      </section>
    </section>
  )
}

export default PrivateRoute
