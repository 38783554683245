import { Switch, BrowserRouter } from 'react-router-dom'
import PrivateRoute from '../comps/PrivateRoute'
import TopMenu from '../comps/TopMenu'
import Home from '../pages/Home/Home'
import Script from '../pages/Script/Script'
import ScriptEdit from '../pages/Script/ScriptEdit'
import EncDec from '../pages/Tools/EncDec/EncDec'
import ScriptGuide from '../pages/Tools/ScriptGuide/ScriptGuide'
import Tools from '../pages/Tools/Tools'
import Sites from '../pages/Sites/Sites'
import Databases from '../pages/Databases/Databases'

const Router = () => {
  return (
    <BrowserRouter>
      <header>
        <TopMenu />
      </header>
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path='/Script' component={Script} />
        <PrivateRoute exact path='/Script/Update/:db?/:num?' component={ScriptEdit} />
        <PrivateRoute exact path='/Script/New/:db?' component={ScriptEdit} />
        <PrivateRoute exact path='/Script/:db?/:num?' component={Script} />
        <PrivateRoute exact path='/Databases' component={Databases} />
        <PrivateRoute exact path='/Webs' component={Sites} />
        <PrivateRoute exact path='/Tools' component={Tools} />
        <PrivateRoute exact path='/Tools/ScriptGuide' component={ScriptGuide} />
        <PrivateRoute exact path='/Tools/EncDec' component={EncDec} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
