import { IBatchResult } from './IBatchResult'
import { ISiteInfo, SiteInfoComplete } from './ISiteInfo'

export interface IClientDatabase {
  adminUrl: string
  server: string
  database: string
  login: string
  password: string
  version: number
  companyName: string
  companyId: string
  dbId: string
  dbType: number
  isOnline: boolean
  lastDate: string
  sites: ISiteInfo[]
  batchErrors: IBatchResult[]
}

export class ClientDatabaseComplete {
  adminUrl: string
  server: string
  database: string
  login: string
  password: string
  version: number
  companyName: string
  companyId: string
  dbId: string
  dbType: number
  isOnline: boolean
  lastDate: string
  sites: SiteInfoComplete[]
  batchErrors: IBatchResult[]

  constructor(clientDb: IClientDatabase) {
    this.adminUrl = clientDb.adminUrl
    this.server = clientDb.server
    this.database = clientDb.database
    this.login = clientDb.login
    this.password = clientDb.password
    this.version = clientDb.version
    this.companyName = clientDb.companyName
    this.companyId = clientDb.companyId
    this.dbId = clientDb.dbId
    this.dbType = clientDb.dbType
    this.isOnline = clientDb.isOnline
    this.lastDate = clientDb.lastDate
    this.sites = clientDb.sites.map(site => {
      return new SiteInfoComplete(site)
    })
    this.batchErrors = clientDb.batchErrors.filter(batchError => batchError.successful === false)
  }

  countBatchErrors = (): number => {
    return this.batchErrors.length
  }

  isWithoutErrors = (): boolean => {
    const allSitesRunning = this.sites.every(
      site => site.isRunning === true || site.isRunning === undefined
    )
    const noBatchErrors = this.countBatchErrors() === 0
    return allSitesRunning && noBatchErrors
  }
}
