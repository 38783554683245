import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IBatchResult } from '../../../types/IBatchResult'
import { ClientDatabaseComplete } from '../../../types/IClientDatabase'
import './BatchModal.scss'

const ERROR_MESSAGE_CHAR_LIMIT = 100

export class BatchErrorItem {
  taskName: string
  batchSetName: string
  errorMessage: string
  executionDate: string
  showFullErrorMessage: boolean

  constructor(batchResult: IBatchResult) {
    this.taskName = batchResult.taskName
    this.batchSetName = batchResult.batchSetName
    this.errorMessage = batchResult.errorMessage
    const date = new Date(batchResult.executionDate)
    this.executionDate = `${date.toLocaleDateString('en-GB')}-${date.toLocaleTimeString('en-GB')}`
    this.showFullErrorMessage = false
  }

  getErrorMessage() {
    if (this.showFullErrorMessage) return this.errorMessage
    if (!this.isErrorMessageExpandable()) return this.errorMessage
    return this.errorMessage.slice(0, ERROR_MESSAGE_CHAR_LIMIT - 1)
  }

  isErrorMessageExpandable() {
    if (!this.errorMessage) return false
    if (this.errorMessage.length <= ERROR_MESSAGE_CHAR_LIMIT) return false
    return true
  }
}

export class BatchErrorData {
  companyName: string
  batchErrorItems: BatchErrorItem[]

  constructor(clientDb: ClientDatabaseComplete) {
    this.companyName = clientDb.companyName
    this.batchErrorItems = clientDb.batchErrors.map(be => new BatchErrorItem(be))
  }
}

type Props = {
  isVisible: boolean
  handleHide: Function
  data: BatchErrorData | undefined
}

const BatchModal = ({ isVisible, handleHide, data }: Props) => {
  const [batchErrors, setBatchErrors] = useState<BatchErrorItem[] | undefined>(
    data?.batchErrorItems
  )

  const changeShowFullMessage = (batchIndex: number) => {
    if (batchErrors === undefined) return
    let errors = batchErrors
    errors[batchIndex].showFullErrorMessage = !errors[batchIndex].showFullErrorMessage
    setBatchErrors([...errors])
  }

  useEffect(() => {
    setBatchErrors(data?.batchErrorItems)
  }, [data])

  return (
    <Modal id='batch-modal' centered size='lg' show={isVisible} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>{data?.companyName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {batchErrors?.map((batchError, index) => {
          return (
            <div key={index}>
              <h5>{batchError.batchSetName}</h5>
              <div className='mb-2'>
                <span className='fw-bold'>{batchError.taskName}</span>
                <div className='text-muted'>[{batchError.executionDate}]</div>
              </div>
              <div className='text-break'>
                {batchError.getErrorMessage()}
                {batchError.isErrorMessageExpandable() && (
                  <div className='d-flex flex-row justify-content-center button-expand-msg-container'>
                    <button
                      className='button-expand-msg'
                      onClick={() => changeShowFullMessage(index)}
                    >
                      {batchError.showFullErrorMessage ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                  </div>
                )}
              </div>
              <hr />
            </div>
          )
        })}
      </Modal.Body>
    </Modal>
  )
}

export default BatchModal
