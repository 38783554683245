import axios from 'axios'
import { useState, useCallback, useEffect } from 'react'
import { DropdownButton } from 'react-bootstrap'
import { useParams } from 'react-router'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import MainContainer from '../../comps/MainContainer'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import './Script.scss'
import ScriptDetail from './comps/ScriptDetail'
import ScriptList from '../../comps/ScriptList'
import Loading from '../../comps/Loading'
import { Link } from 'react-router-dom'
import { FaPlus, FaRegEdit, FaDownload, FaTrashAlt } from 'react-icons/fa'
import { useDebounce } from 'use-debounce'

type urlParams = {
  db: string
  num: string
}

const Script = () => {
  const params: urlParams = useParams()
  const [scripts, setScripts] = useState<IScriptDetailItem[]>([])
  const [script, setScript] = useState<IScriptDetailItem>()
  const [searchField, setSearchField] = useState<string>('')
  const [debouncedSearchField] = useDebounce(searchField, 1000)
  const [isLoadingScripts, setIsLoadingScripts] = useState<boolean>(false)

  if (params.db === undefined) params.db = '1'

  const searchScripts = useCallback(async () => {
    setIsLoadingScripts(true)
    const data = await axios.get<IScriptDetailItem[]>(
      `${API_BASE_URL}/Script/Search?db=${params.db}&pattern=${debouncedSearchField}`
    )
    setIsLoadingScripts(false)
    if (data.status >= 200 && data.status < 300) {
      if (data.data.length > 0) {
        if (!data.data.find(s => s.number.toString() === params.num)) {
          params.num = data.data[0].number.toString()
        }
      }
      setScripts(data.data)
      setScript(data.data.find(s => s.number.toString() === params.num))
    }
  }, [params, debouncedSearchField])

  const onScriptClicked = (scr: IScriptDetailItem) => {
    params.num = scr.number.toString()
    setScript(scr)
  }

  const getDbName = useCallback(() => {
    switch (params.db) {
      case '1':
        return 'Benefit'
      case '2':
        return 'Pasport'
      case '3':
        return 'Doprava'
    }
    return 'Neznáma databáze'
  }, [params.db])

  useEffect(() => {
    searchScripts()
  }, [searchScripts])

  return (
    <MainLayout>
      <MainContainer>
        <section id='script-wrapper' className='h-100'>
          <div className='top-bar d-flex flex-row justify-content-between p-2'>
            <div className='d-flex flex-row justify-content-start'>
              <DropdownButton variant='secondary' id='dropdown-basic-button' title={getDbName()}>
                <Link className='dropdown-item' to='/Script/1' onClick={() => setSearchField('')}>
                  Benefit
                </Link>
                <Link className='dropdown-item' to='/Script/2' onClick={() => setSearchField('')}>
                  Pasport
                </Link>
                <Link className='dropdown-item' to='/Script/3' onClick={() => setSearchField('')}>
                  Doprava
                </Link>
              </DropdownButton>
              <div className='icons-container'>
                <Link className='icon' to={`/Script/New/${params.db}`}>
                  <FaPlus />
                </Link>
                <Link
                  className='icon'
                  to={`/Script/Update/${script?.databaseId}/${script?.number}`}
                >
                  <FaRegEdit />
                </Link>
                <Link className='icon' to='/'>
                  <FaDownload />
                </Link>
              </div>
            </div>
            <div className='d-flex flex-row justify-content-end'>
              <input
                type='text'
                className='form-control search-field'
                placeholder='Hledat...'
                value={searchField}
                onChange={e => setSearchField(e.target.value)}
              />
              {searchField.length > 0 && (
                <button
                  className='btn btn-outline-danger'
                  onClick={() => {
                    setSearchField('')
                  }}
                >
                  <FaTrashAlt />
                </button>
              )}
            </div>
          </div>
          {!isLoadingScripts && scripts.length === 0 ? (
            <h3>Žádné skripty nenalezeny</h3>
          ) : (
            <div className='d-flex flex-row row-content gx-0'>
              <div className='scrollable-column col-3'>
                {isLoadingScripts ? (
                  <div className='h-100 d-flex flex-column justify-content-center'>
                    <div className='d-flex flex-row justify-content-center'>
                      <Loading />
                    </div>
                  </div>
                ) : (
                  <div className='p-3'>
                    <ScriptList
                      scripts={scripts}
                      selectedNum={params.num}
                      onScriptClicked={onScriptClicked}
                    />
                  </div>
                )}
              </div>
              <div className='scrollable-column col-9'>
                {script !== undefined && (
                  <div className='p-3'>
                    <ScriptDetail script={script} />
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      </MainContainer>
    </MainLayout>
  )
}

export default Script
